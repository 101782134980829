.welcome-banner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 16rem;
}

.home .welcome-banner {
  max-width: 100%;
}

.welcome-banner img {
  top: 0;
}

@media (min-width: 1009px) {
  .welcome-banner {
    height: auto;
  }
  .welcome-banner img {
    width: 100%;
  }
}