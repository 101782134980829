.header {
  height: 1.75rem;
  background: darkblue;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 200;
  padding: 0.5rem 1rem 0 1rem;
  display: flex;
  justify-content: space-between;
}

.header__menu {
  display: flex;
  flex-direction: row;
}

.header__logo {
  font-family: 'Recursive', sans-serif;
  text-decoration: none;
  color: white;
}

.header__item {
  margin-left: 2rem;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
}

.header__item:hover div {
  position: absolute;
  top: 1.25rem;
  right: 0;
  background-color: white;
  width: 12rem;
  height: auto;
  display: inline-flex;
  flex-direction: column;
  border-radius: 0.5rem;
}

.header__item a {
  display: none;
}

.header__item:hover a {
  display: inline;
  text-decoration: none;
  padding: 0.5rem;
  color: black;
}

.header__item:hover a:first-child {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.header__item:hover a:last-child {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.header__item:hover a:hover {
  background-color: lightblue;

}
