@import url(https://fonts.googleapis.com/css2?family=Recursive&family=Ubuntu&display=swap);
:root {

  /* Colors */
  --blue-0: white;
  --blue-5: rgb(233, 244, 255);
  --blue-10: rgb(210, 233, 255);
  --blue-15: rgb(188, 222, 255);
  --blue-20: rgb(165, 211, 255);
  --blue-25: rgb(143, 200, 255);
  --blue-30: rgb(121, 188, 255);
  --blue-35: rgb(98, 177, 255);
  --blue-40: rgb(76, 166, 255);
  --blue-45: rgb(53, 155, 255);
  --blue-50: #1F90FF;
  --blue-60: rgb(25, 115, 204);
  --blue-70: rgb(19, 86, 153);
  --blue-80: rgb(12, 58, 102);
  --blue-90: rgb(6, 29, 51);
  --purple-10: rgb(233, 226, 248);
  --purple-20: rgb(212, 198, 240);
  --purple-30: rgb(190, 169, 233);
  --purple-40: rgb(169, 141, 225);
  --purple-50: #9370DA;
  --purple-60: rgb(118, 90, 174);
  --purple-70: rgb(88, 67, 131);
  --purple-80: rgb(59, 45, 87);
  --purple-90: rgb(29, 22, 44);
  --orange-10: rgb(255, 229, 204);
  --orange-20: rgb(255, 204, 153);
  --orange-30: rgb(255, 178, 102);
  --orange-40: rgb(255, 153, 51);
  --orange-50: #FF7F00;
  --orange-60: rgb(204, 102, 0);
  --orange-70: rgb(153, 76, 0);
  --orange-80: rgb(102, 51, 0);
  --orange-90: rgb(51, 25, 0);
  --yellow-10: rgb(255, 255, 204);
  --yellow-20: rgb(255, 255, 153);
  --yellow-30: rgb(255, 255, 102);
  --yellow-40: rgb(255, 255, 51);
  /* --yellow-50: #FFFF99; */
  --yellow-50: yellow;
  --yellow-60: rgb(204, 204, 0);
  --yellow-70: rgb(153, 153, 0);
  --yellow-80: rgb(102, 102, 0);
  --yellow-90: rgb(51, 51, 0);
  --white: #FFFFFF;
  --gray-10: rgb(230, 230, 230);
  --gray-20: rgb(204, 204, 204);
  --gray-30: rgb(179, 179, 179);
  --gray-40: rgb(153, 153, 153);
  /* --gray-50: #D3D3D3; */
  --gray-50: gray;
  --gray-60: rgb(102, 102, 102);
  --gray-70: rgb(77, 77, 77);
  --gray-80: rgb(51, 51, 51);
  --gray-90: rgb(26, 26, 26);
  --green-10: rgb(206, 248, 220);
  --green-20: rgb(157, 240, 185);
  --green-30: rgb(108, 233, 150);
  --green-40: rgb(59, 225, 115);
  --green-50: #0ADA50;
  --green-60: rgb(8, 174, 64);
  --green-70: rgb(6, 131, 48);
  --green-80: rgb(4, 87, 32);
  --green-90: rgb(2, 44, 16);

  /* Font Families */
  --ubuntu: 'Ubuntu', sans-serif;
  --roboto-slab: 'Roboto Slab', serif;
  --bevan: 'Bevan', cursive;
  --cairo: 'Cairo', sans-serif;
  --press-start-2p: 'Press Start 2P', cursive;

  /* Spacing Units */
  --half-spacing-unit: 4px;
  --spacing-unit: 8px;
  --spacing-unit-x2: 16px;
  --spacing-unit-x3: 24px;
  --spacing-unit-x4: 32px;
  --spacing-unit-x5: 40px;
  --spacing-unit-x6: 48px;
}

body {
  font-family: 'Ubuntu', sans-serif;
  font-family: var(--ubuntu);
  margin: 0;
}

body, #root, #root > div {
  min-height: 100vh;
}

.existential-survey {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.welcome-banner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 16rem;
}

.home .welcome-banner {
  max-width: 100%;
}

.welcome-banner img {
  top: 0;
}

@media (min-width: 1009px) {
  .welcome-banner {
    height: auto;
  }
  .welcome-banner img {
    width: 100%;
  }
}
.contact-form__container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.contact-form {
  width: 32rem;
  display: inline-flex;
  flex-direction: column;
}

.contact-form span {
  font-size: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 0.25rem;
}

.contact-form input {
  height: 1.25rem;
  border-radius: 0.35rem;
  padding: 0.5rem;
}

.contact-form input.no-border-radius {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  flex-grow: 1;
}

.contact-form select {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.contact-form textarea {
  font-family: 'Ubuntu', sans-serif;
  padding: 1rem;
}

.contact-form button {
  align-self: center;
  display:inline-block;
  padding:0.5em 1.6em;
  margin:0 0.3em 0.3em 0;
  border-radius:2em;
  box-sizing: border-box;
  text-decoration:none;
  font-family:'Ubuntu',sans-serif;
  font-weight:300;
  color: #000000;
  background-color:#7accfc;
  text-align:center;
  transition: all 0.2s;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.contact-form button:hover {
  color:#000000;
  background-color:#4095c6;
  cursor: pointer;
}

@media (max-width: 768px) {
  .contact-form {
    width: auto;
  }
}
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profiles {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.profiles img {
  height: 24rem;
  width: 24rem;
  object-fit: cover;
  border: 3px ridge darkblue;
}

.profiles img.profiles__other {
  border-radius: 50%;
  height: 16rem;
  width: 16rem;
  margin: 0 4rem; 
}

.profiles img.profiles__me {
  display: none;
}

.welcome-message {
  font-size: 2rem;
  width: 100%;
  text-align: center;
  margin-top: 2.5rem;
}

.welcome-description {
  width: 67%;
  align-self: center;
  text-align: center;
  margin-top: 0.75rem;
  line-height: 1.75rem;
}

@media (min-width: 768px) {
  .profiles {
    flex-direction: row;
  }

  .profiles img.profiles__me {
    display: inline-block;
  }

  .profiles img.profiles__me-mobile {
    display: none;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.header {
  height: 1.75rem;
  background: darkblue;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 200;
  padding: 0.5rem 1rem 0 1rem;
  display: flex;
  justify-content: space-between;
}

.header__menu {
  display: flex;
  flex-direction: row;
}

.header__logo {
  font-family: 'Recursive', sans-serif;
  text-decoration: none;
  color: white;
}

.header__item {
  margin-left: 2rem;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
}

.header__item:hover div {
  position: absolute;
  top: 1.25rem;
  right: 0;
  background-color: white;
  width: 12rem;
  height: auto;
  display: inline-flex;
  flex-direction: column;
  border-radius: 0.5rem;
}

.header__item a {
  display: none;
}

.header__item:hover a {
  display: inline;
  text-decoration: none;
  padding: 0.5rem;
  color: black;
}

.header__item:hover a:first-child {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.header__item:hover a:last-child {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.header__item:hover a:hover {
  background-color: lightblue;

}

